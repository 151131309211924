import { inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { TranslocoService } from '@jsverse/transloco';
import { environment } from '../../../environments/environment';
import { Params, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class UrlHelperService {
  translocoService = inject(TranslocoService);
  location = inject(Location);
  router = inject(Router);

  getSlug(slug: string, lang?: string): string {
    let path = slug.startsWith('/') ? slug : '/' + slug;
    const replaceValue = lang? '/' + lang : '';
    path = path.replace(/^\/suedo/, replaceValue);
    return path;
  }
  public getUrlPath(link = '', prefix?: string) {
    let lang = '/' + this.translocoService.getActiveLang();
    let path = link.startsWith('/') ? link : '/' + link;
    path = path.replace(/^\/suedo/, '');

    if (prefix) {
      lang = `${lang}/${prefix}`;
    }

    lang += path;
    return lang.replace(/\/$/, '');
  }

  public static normalize(path: string): string {
    path = path.trim();
    path = path.toLowerCase();
    path = path.replace(/ä/g, 'ae');
    path = path.replace(/ö/g, 'oe');
    path = path.replace(/ü/g, 'ue');
    path = path.replace(/ß/g, 'ss');
    path = path.replace(/é/g, 'e');
    path = path.replace(/è/g, 'e');
    path = path.replace(/ê/g, 'e');
    path = path.replace(/â/g, 'a');
    path = path.replace(/à/g, 'a');
    path = path.replace(/[^a-z0-9-/]/gi, '-');
    path = path.replace(/-+/g, '-');
    return path;
  }

  public enforceAbsoluteUrl(url?: string): string {
    url = url ?? this.location.path();

    return url.match('^http') ? url : `${environment.host}${url}`;
  }

  public getPath(): string {
    return this.getPathWithoutParams();
  }

  private getPathWithoutParams(): string {
    const url: string = this.location.path();
    if (!url.match(/\?/)) {
      return url;
    }

    return url.substring(0, url.lastIndexOf('?'));
  }

  public getQueryParams(): Params {
    return this.router.parseUrl(this.location.path()).queryParams || {};
  }
}
