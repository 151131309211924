import { inject, Pipe, PipeTransform } from '@angular/core';
import { UrlHelperService } from '../services/url/url-helper.service';

@Pipe({
  name: 'urlPath',
  standalone: true,
})
export class UrlPathPipe implements PipeTransform {
  urlHelperService = inject(UrlHelperService);

  public transform(path: string, prefix?: string): string {
    return this.urlHelperService.getUrlPath(path, prefix);
  }
}
